import React, { useState } from 'react';
import { CloseButton, Container, Dropdown } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';

//image here

import BrandImage from '../../assets/images/brand_logo.png';

const Navbar = () => {
  const location = useLocation();

  const [navInfo, setNavInfo] = useState(false);
  return (
    <>
      <Container>
        <nav className=' navWrapper'>
          <figure className='navWrapper__navBrand'>
            <Link to='/'>
              <img src={BrandImage} alt='' />
            </Link>
          </figure>

          <ul className='navWrapper__navItems'>
            <li className='navWrapper__navItems--items'>
              <NavLink
                className={`${
                  location.pathname === '/' ? 'activeSidebar' : 'notActive'
                }`}
                to='/'
              >
                Home
              </NavLink>
            </li>
            <li className='navWrapper__navItems--items'>
              <NavLink
                className={(navData) =>
                  navData.isActive ? 'activeSidebar' : 'notActive'
                }
                to='/members'
              >
                Members
              </NavLink>
            </li>
            {/* <li className='navWrapper__navItems--items'>
              <NavLink
                className={(navData) =>
                  navData.isActive ? 'activeSidebar' : 'notActive'
                }
                to='/news'
              >
                News
              </NavLink>
            </li> */}
            <li className='navWrapper__navItems--items'>
              {/* <NavLink
                className={(navData) =>
                  navData.isActive ? 'activeSidebar' : 'notActive'
                }
                to='/contact-us'
              >
                Contact
              </NavLink> */}
              <a
                className={`${
                  location.pathname === '/contact-us'
                    ? 'activeSidebar'
                    : 'notActive'
                }`}
                href='/contact-us'
              >
                Contact
              </a>
            </li>
            <li className='navWrapper__navItems--items'>
              <a
                className={(navData) =>
                  navData.isActive ? 'activeSidebar' : 'notActive'
                }
                href='/application'
              >
                <button className='bton bton--md bton--primary'>
                  Become a member
                </button>
              </a>
            </li>
          </ul>

          <section className='navWrapper__mdNav'>
            <section className='nav_mdInfo'>
              {
                <button className='open_mdNav' onClick={() => setNavInfo(true)}>
                  &#9776;
                </button>
              }
            </section>
            {
              <section
                className={`mdNav_list_container ${navInfo && ' showmdNav'}`}
              >
                <ul className='mdNav_list'>
                  <li>
                    <figure className='mdnavBrand'>
                      <Link to='/'>
                        <img src={BrandImage} alt='' />
                      </Link>
                    </figure>
                    <div className='close_mdNav_container'>
                      <button
                        className='close_mdNav'
                        onClick={() => setNavInfo(false)}
                      >
                        <CloseButton />{' '}
                      </button>
                    </div>
                  </li>

                  <li
                    className='navWrapper__navItems--items'
                    onClick={() => setNavInfo(false)}
                  >
                    <NavLink
                      className={`${
                        location.pathname === '/'
                          ? 'activeSidebar'
                          : 'notActive'
                      }`}
                      to='/'
                    >
                      Home
                    </NavLink>
                  </li>
                  <li
                    className='navWrapper__navItems--items'
                    onClick={() => setNavInfo(false)}
                  >
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? 'activeSidebar' : 'notActive'
                      }
                      to='/members'
                    >
                      Members
                    </NavLink>
                  </li>
                  <li
                    className='navWrapper__navItems--items'
                    onClick={() => setNavInfo(false)}
                  >
                    {/* <NavLink
                      className={(navData) =>
                        navData.isActive ? 'activeSidebar' : 'notActive'
                      }
                      to='/contact-us'
                    >
                      Contact
                    </NavLink> */}

                    <a
                      className={`${
                        location.pathname === '/contact-us'
                          ? 'activeSidebar'
                          : 'notActive'
                      }`}
                      href='/contact-us'
                    >
                      Contact
                    </a>
                  </li>
                  <li
                    className='navWrapper__navItems--items'
                    onClick={() => setNavInfo(false)}
                  >
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? 'activeSidebar' : 'notActive'
                      }
                      to='/application'
                    >
                      Application Form
                    </NavLink>
                  </li>
                </ul>
              </section>
            }
          </section>
        </nav>
      </Container>
    </>
  );
};

export default Navbar;
