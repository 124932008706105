import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Widget() {
  return (
    <Container>
      <section className='flex-between widgetWrapper'>
        <h2>
          Doing electrical work? Find a licensed electrical contractor on ESA’s
          website
        </h2>
        <a
          href={'https://findacontractor.esasafe.com '}
          target='_blank'
          rel='noreferrer noopener'
        >
          <button className='bton bton--lg bton--primary'>
            visit&nbsp;website
          </button>
        </a>
      </section>
    </Container>
  );
}

export default Widget;
