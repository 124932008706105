import gql from 'graphql-tag';

export const MEMBER_QUERY = gql`
  query members($id: ID!) {
    page(id: $id) {
      id
      members {
        memberSection {
          image {
            sourceUrl
          }
          link
          memberName
        }
      }
    }
  }
`;
