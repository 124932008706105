import React from 'react';
import { Link } from 'react-router-dom';
import InterestImage from '../../assets/images/interested.png';

const InterestedMember = () => {
  return (
    <>
      <section className='interestedWrapper'>
        <figure className='interestedWrapper-image'>
          <img src={InterestImage} alt='' />

          <section className='interestedWrapper-image-desc'>
            <h1>Interested in becoming a member?</h1>
            <p>
              When you become an HDECA member you will be a part of an Non
              Profit Organization with the goal of enriching the Industry &
              giving back to the community. You will have exclusive access to
              training, new product demonstrations, & monthly meeting both in
              person & virtually. Throughout the year our monthly meetings could
              consist of ESA Inspection Night, Alectra Night, Safety Seminars,
              First Aid Courses, Certification Courses. Product Demonstrations &
              Social Events. Including our Charity Golf Tournament in September.
              (no meetings in June, July or August.)
            </p>

            <a href='/application'>
              <button className='bton bton--lg bton--primary'>
                Become a member
              </button>
            </a>
          </section>
        </figure>

        <section>
          <section className='interestedWrapper-red'></section>
        </section>
      </section>
    </>
  );
};

export default InterestedMember;
