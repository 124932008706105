import React, { useEffect } from 'react';
import FirstAbout from '../../components/FirstAbout';
import MembersList from '../../components/MembersList';
import InterestedMember from '../../components/InterestedMember';
import Widget from '../../components/Widget';

const Members = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FirstAbout />
      <MembersList />
      <InterestedMember />
      <Widget />
    </>
  );
};

export default Members;
