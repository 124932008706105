import gql from 'graphql-tag';

export const CAROUSEL_QUERY = gql`
  query carousels {
    page(id: "cG9zdDo4") {
      id
      heroSection {
        section {
          buttonText
          title
          description
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;
