import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [contactForm, setContactForm] = useState({
    fullname: '',
    email: '',
    company: '',
    message: '',
  });

  const { fullname, email, company, message } = contactForm;
  const captchaRef = useRef(null);

  const [sentSuccess, setSentSuccess] = useState(null);
  const [sending, setSending] = useState(false);

  const [validate, setValidate] = useState({
    fullname: false,
    email: false,
    company: false,
    message: false,
  });

  const validateForm = async () => {
    var isValid = true;
    if (fullname.trim() === '') {
      setValidate((prev) => ({ ...prev, fullname: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, fullname: false }));
    }
    if (email.trim() === '') {
      setValidate((prev) => ({ ...prev, email: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, email: false }));
    }
    if (company.trim() === '') {
      setValidate((prev) => ({ ...prev, company: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, company: false }));
    }
    if (message.trim() === '') {
      setValidate((prev) => ({ ...prev, message: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, message: false }));
    }

    return isValid;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendMail = async () => {
    const isValid = await validateForm();
    if (isValid) {
      const token = captchaRef.current.getValue();
      setSending(true);
      const response = await axios.post(
        'https://backend.hamiltondeca.com/api/send',
        {
          ...contactForm,
          token,
        }
      );
      if (response.data.status === 'success') {
        captchaRef.current.reset();
        setContactForm({
          fullname: '',
          email: '',
          company: '',
          message: '',
        });
        setSentSuccess(true);
        setSending(false);
        setTimeout(() => {
          setSentSuccess(null);
        }, 3000);
      } else {
        setSending(false);
        setSentSuccess(false);
        setTimeout(() => {
          setSentSuccess(null);
        }, 3000);
      }
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={5}>
            <section className='formWrapper'>
              <h1>
                Lets Talk, <strong className='cpr'>Say Hi !</strong>{' '}
              </h1>
              <p>
                Drop your message below, and let me know if you are intrested in
                becoming a member or other enquiries
              </p>

              <Form>
                <FloatingLabel
                  controlId='floatingInput'
                  label='Full Name'
                  className='mt-3'
                >
                  <Form.Control
                    type='text'
                    placeholder='data'
                    value={fullname}
                    onChange={(e) => {
                      setContactForm({
                        ...contactForm,
                        fullname: e.target.value,
                      });
                    }}
                  />
                  {validate.fullname && (
                    <small style={{ color: 'red' }}>Fullname is required</small>
                  )}
                </FloatingLabel>
                <FloatingLabel
                  controlId='floatingInput'
                  label='Email'
                  className='mt-3'
                >
                  <Form.Control
                    type='email'
                    placeholder='data'
                    value={email}
                    onChange={(e) => {
                      setContactForm({
                        ...contactForm,
                        email: e.target.value,
                      });
                    }}
                  />
                  {validate.email && (
                    <small style={{ color: 'red' }}>Email is required</small>
                  )}
                </FloatingLabel>
                <FloatingLabel
                  controlId='floatingInput'
                  label='Company Name'
                  className='mt-3'
                >
                  <Form.Control
                    type='text'
                    placeholder='data'
                    value={company}
                    onChange={(e) => {
                      setContactForm({
                        ...contactForm,
                        company: e.target.value,
                      });
                    }}
                  />
                  {validate.company && (
                    <small style={{ color: 'red' }}>Company is required</small>
                  )}
                </FloatingLabel>

                <FloatingLabel controlId='floatingTextarea2' label='Message'>
                  <Form.Control
                    as='textarea'
                    placeholder='comment'
                    style={{ height: '100px' }}
                    className='mt-3 mb-3'
                    value={message}
                    onChange={(e) => {
                      setContactForm({
                        ...contactForm,
                        message: e.target.value,
                      });
                    }}
                  />
                  {validate.message && (
                    <small style={{ color: 'red' }}>Message is required</small>
                  )}
                </FloatingLabel>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                />
                {!sending ? (
                  <button
                    className='bton bton--primary bton--full mt-3'
                    type='submit'
                    onClick={(e) => {
                      e.preventDefault();
                      sendMail();
                    }}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className='bton bton--primary bton--full mt-3'
                    type='submit'
                    disabled={true}
                  >
                    Submiting
                  </button>
                )}
              </Form>

              {sentSuccess !== null && (
                <section className='feedbackSection'>
                  {sentSuccess === true && (
                    <div className='feedbackSection-success'>
                      Form Submitted SuccessFully !!
                    </div>
                  )}
                  {sentSuccess === false && (
                    <div className='feedbackSection-failure'>
                      Something Went Wrong !! Try Again
                    </div>
                  )}
                </section>
              )}
            </section>
          </Col>
          <Col md={6}>
            {/* <section className='formWrapper'>
              <h1>Our Location</h1>
              <iframe
                title='map'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905.37133163371!2d-79.88141718467176!3d43.26459587913655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b7e124dd123%3A0x8ed7e8340cd6936c!2s370%20York%20Blvd%20Suite%20100%2C%20Hamilton%2C%20ON%20L8R%203L1%2C%20Canada!5e0!3m2!1sen!2snp!4v1665837272193!5m2!1sen!2snp'
                width='100%'
                height='500'
                // style='border:0;'
                // allowfullscreen=''
                // loading='lazy'
                // referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </section> */}

            <p className='contactInfo'>
              <strong>HDECA</strong> is made up of a group of Electrical
              Contractors & Suppliers interested in the same endeavors: Promote
              the importance of the Electrical Trade within the Community.{' '}
              <br /> Promote Public Understanding of the proper electrical
              installations, Pursue all possible improvements to the trade, Make
              every effort to improve the Skills & Safety of our Employees.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
