import React from 'react';
import { Link } from 'react-router-dom';

const FirstAbout = () => {
  return (
    <section className='first_about_main_container'>
      <section className='first_about_container'>
        <div className='first_about_header'>
          Become a member of our association
        </div>
        <Link to='/application'>
          <button className='bton bton--ghost bton--lg'> Join HDECA Now</button>
        </Link>
      </section>
    </section>
  );
};

export default FirstAbout;
