import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section role='Footer'>
        <Container>
          <Row className='justify-content-between'>
            <Col md={4}>
              <section className='foooterInfo'>
                <header className='Links-title'>
                  <h2>About HDECA</h2>
                </header>
                <p>
                  The Hamilton & District Electrical Contractors Association for
                  over 90 years has been the local voice for independent
                  licensed contractors. We represent contractors, distributors,
                  manufacturers, utilities and ESA. Our chapter is affiliated
                  with the Ontario Electrical League.
                </p>
              </section>
            </Col>
            <Col md={2}>
              <section className='Links'>
                <header className='Links-title'>
                  <h2>Quick links</h2>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/members'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i> Our Members{' '}
                    </Link>
                  </li>
                  {/* <li className='Links-box-item'>
                    {' '}
                    <Link to='/products'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i>About Us
                    </Link>
                  </li> */}
                  <li className='Links-box-item'>
                    {' '}
                    <a href='/contact-us'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i>Contact Us
                    </a>
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/application'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i>
                      Membership Application
                    </Link>
                  </li>
                </ul>
              </section>
            </Col>
            {/* <Col md={2}> */}
            {/* <section className='Links'>
                <header className='Links-title'>
                  <h2>Membership</h2>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/products'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i> Become a
                      member{' '}
                    </Link>
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/products'>
                      {' '}
                      <i className='fa fa-chevron-right me-2'></i> Members
                    </Link>
                  </li>
                </ul>
              </section> */}
            {/* <section className='socialMedia'>
                <header className='socialMedia-title'>
                  <h2>Find Us On</h2>
                </header>

                <section className='socialWrapper'>
                  <a href={'/'} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i class='fa fa-facebook-f'></i>
                    </div>
                  </a>

                  <a href={'/'} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-instagram'></i>
                    </div>
                  </a>
                  <a href={'/'} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-twitter'></i>
                    </div>
                  </a>
                </section>
              </section> */}
            {/* </Col> */}
            <Col md={4}>
              <section className='contact'>
                <header className='contact-title'>
                  <h2>Reach Us</h2>
                </header>

                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Mailing Address</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        Suite 100 370 York Boulevard Hamilton, ON L8R 3L1
                      </div>
                    </div>
                  </li>
                  {/* <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Phone</strong>{' '}
                      </div>
                      <div className='addHolder-value '>905-930-8999 </div>
                    </div>
                  </li> */}

                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-envelope'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Email</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        <a
                          href='mailto:
hamiltondeca@hotmail.com'
                        >
                          <span style={{ color: 'white' }}>
                            hamiltondeca@hotmail.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
          </Row>

          <hr />
          <section className='copyright'>
            Hamilton & District Electrical Contractors Association &copy;{' '}
            {new Date().getFullYear()} All Rights Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
