/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Query from '../Query';
import { MEMBER_QUERY } from '../../queries/members';
import MemberContext from '../../context/MemberContext';

const MembersList = () => {
  const pageSize = 12;
  const [loadMore, setLoadMore] = useState(true);
  const [index, setIndex] = useState(pageSize);

  const memberContext = useContext(MemberContext);
  const { members } = memberContext;

  const [filteredMember, setFilteredMember] = useState([]);
  const [keys, setKeys] = useState([]);
  const [length, setLength] = useState(0);
  const [list, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setKeys([...Array(members.length).keys()]);
    setList([...Array(members.length).keys()].slice(0, pageSize));
    setLength(members.length);
  }, [members]);

  const loadMoreData = () => {
    const newIndex = index + pageSize;
    const newLoadMore = newIndex <= length - 1;
    const newList = list.concat(keys.slice(index, newIndex));
    setIndex(newIndex);
    setList(newList);
    setLoadMore(newLoadMore);
  };

  return (
    <Query query={MEMBER_QUERY} id={'cG9zdDoxNQ=='}>
      {({ data: { page } }) => {
        return (
          <section className='membersWrapper'>
            <Container>
              <header className='headTitle'>
                <div className='headTitle-sub'>OUR MEMBERS </div>
                <h1 className='headTitle-main'>Members</h1>
              </header>

              <section className='searchMember'>
                <input
                  type='search'
                  placeholder='search members...'
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (e.target.value === '') {
                      const localMember = members;

                      setKeys([...Array(localMember.length).keys()]);
                      setList(
                        [...Array(localMember).length.keys()].slice(0, pageSize)
                      );
                      setLength(localMember.length);
                    } else {
                      setFilteredMember([
                        ...members.filter((mem) => {
                          if (
                            mem?.memberName
                              ?.toString()
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }),
                      ]);
                    }
                  }}
                />
              </section>

              {searchTerm === '' ? (
                <Row>
                  {list.length > 0 &&
                    list.map((m) => (
                      <Col lg={2} md={3} sm={6}>
                        <a href={members[m]?.link} target='_new'>
                          <figure className='membercard'>
                            {members[m].image !== null ? (
                              <img src={members[m]?.image?.sourceUrl} alt='' />
                            ) : (
                              <div className='noImage'>
                                {members[m]?.memberName.substring(2, 0)}
                              </div>
                            )}

                            <figcaption>{members[m]?.memberName}</figcaption>
                          </figure>
                        </a>
                      </Col>
                    ))}
                </Row>
              ) : (
                <Row>
                  {filteredMember.length > 0 &&
                    filteredMember.map((m) => (
                      <Col lg={2} md={3} sm={6}>
                        <a href={m?.link} target='_new'>
                          <figure className='membercard'>
                            {m.image !== null ? (
                              <img src={m?.image?.sourceUrl} alt='' />
                            ) : (
                              <div className='noImage'>
                                {m?.memberName.substring(2, 0)}
                              </div>
                            )}

                            <figcaption>{m?.memberName}</figcaption>
                          </figure>
                        </a>
                      </Col>
                    ))}
                </Row>
              )}

              <section className='text-center m-5'>
                {searchTerm === '' && loadMore && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      loadMoreData();
                    }}
                    className='bton bton--sm bton--ghost'
                  >
                    Load more
                  </button>
                )}
              </section>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default MembersList;
