import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CarouselHero from '../../components/CarouselHero/index';
import AboutImage from '../../assets/images/abouut_image.png';
import CharityImg from '../../assets/images/Charity Pic 1.jpg';
import CharityImg3 from '../../assets/images/Charity Pic 3.jpg';
import CharityImg6 from '../../assets/images/oct dinner 3 (1).jpg';
import Widget from '../../components/Widget';
// import CharityImg2 from '../../assets/images/Charity Pic 2.jpg';
// import CharityImg4 from '../../assets/images/oct dinner (1).jpg';
// import CharityImg5 from '../../assets/images/oct dinner 2 (1).jpg';

const Home = () => {
  const count = [1, 1, 1, 1, 11, 11, 5, 1, 1, 11, 1, 5];
  return (
    <>
      {' '}
      <section role='hero'>
        <div className='herocontainer'>
          <CarouselHero />
        </div>
      </section>
      <Container>
        {/* <section className='statsWrapper'>
          <Row className='justify-content-center'>
            <Col md={2}>
              <article>
                <div className='statsWrapper-number'>100 +</div>
                <div className='statsWrapper-label'>members</div>
              </article>
            </Col>
            <Col md={2}>
              <article>
                <div className='statsWrapper-number'>200 +</div>
                <div className='statsWrapper-label'>companies</div>
              </article>
            </Col>
            <Col md={2}>
              <article>
                <div className='statsWrapper-number'>1000 +</div>
                <div className='statsWrapper-label'>projects</div>
              </article>
            </Col>
          </Row>
        </section> */}

        <section className='aboutWrapper'>
          <header className='headTitle'>
            {/* <div className='headTitle-sub'>WHO is HDEAC ?</div> */}
            <h1 className='headTitle-main'>About HDECA </h1>
          </header>

          <Row className='justify-content-between'>
            <Col md={5}>
              <section className='aboutContent'>
                <p>
                  For over 90 years the Hamilton &amp; District Electrical
                  Contractors Association has been the voice for independent
                  licensed contractors.
                  <br /> <br /> We are a group comprised of contractors,
                  distributors, manufacturers , utilities and ESA. We are
                  affiliated with the Ontario Electrical league as the Hamilton
                  Chapter, this allows our chapter to be the local voice for a
                  provincial forum. Providing access to group discounts,
                  training and other exclusive offers.
                </p>
              </section>
            </Col>

            <Col md={6}>
              <figure className='aboutImage'>
                <img src={AboutImage} alt='' />
              </figure>
            </Col>
          </Row>
        </section>
        {/* <section className='eventWrapper'>
          <section className='flex-between'>
            <header className='headTitle'>
              <div className='headTitle-sub'>OUR UPCOMMING EVENTS</div>
              <h1 className='headTitle-main'>Events</h1>
            </header>

            <button className='bton bton--ghost bton--sm'>See All</button>
          </section>

          <Row>
            {count.map((items) => (
              <Col md={2}>
                <section className='eventCard'>
                  <div className='eventCard-month'>April</div>
                  <div className='eventCard-day'>25</div>
                  <div className='eventCard-title'>
                    title of a sample side od a kjfsa lasdfh laskhf lsaslk slss
                    sls fasdfdsa dfasf dasf dsaa fas
                  </div>
                </section>
              </Col>
            ))}
          </Row>
        </section> */}
      </Container>
      <section className='charityWrapper'>
        <Container>
          <h1 className='charityWrapper-title'>
            We give back to both the Community and Local Charities
          </h1>
          <p className='charityWrapper-desc'>
            Through our own fund raising initiatives we have raised and donated
            over <strong> $100,000</strong> to local charities or school
            programs involving children.
          </p>
          <Row>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg} alt='' />
              </figure>
            </Col>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg6} alt='' />
              </figure>
            </Col>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg3} alt='' />
              </figure>
            </Col>
            {/* <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg4} alt='' />
              </figure>
            </Col>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg5} alt='' />
              </figure>
            </Col>
            <Col md={4}>
              <figure className='charityWrapper-image'>
                <img src={CharityImg6} alt='' />
              </figure>
            </Col> */}
          </Row>
        </Container>
      </section>
      <Widget />
    </>
  );
};

export default Home;
