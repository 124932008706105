import { useReducer } from 'react';
import MemberContext from './MemberContext';
import MemberReducer from './MemberReducer';

const MemberState = (props) => {
  const initialState = {
    members: [],
  };
  const [state, dispatch] = useReducer(MemberReducer, initialState);

  const setMember = async (members) => {
    try {
      dispatch({ type: 'SET_MEMBER', payload: members });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MemberContext.Provider
      value={{
        members: state.members,
        setMember,
      }}
    >
      {props.children}
    </MemberContext.Provider>
  );
};

export default MemberState;
