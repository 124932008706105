import React, { Fragment } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import Hero1 from '../../assets/images/hero1.png';
import { CAROUSEL_QUERY } from '../../queries/carousals';
import Query from '../Query';
function Index() {
  return (
    <Fragment>
      <Query query={CAROUSEL_QUERY}>
        {({ data: { page } }) => {
          return (
            <Carousel fade>
              {page.heroSection.section.map((hero, i) => {
                return (
                  <Carousel.Item>
                    <img
                      className='d-block careouselImage'
                      src={hero.image.sourceUrl}
                      alt='First slide'
                    />

                    <div className='heroOverlay'></div>
                    <Carousel.Caption>
                      <section className='carouselInfo'>
                        <h1> {hero.title}</h1>
                        <p>{hero.description}</p>
                        <Link to='/contact-us'>
                          <button className='bton bton--ghost bton--md mt-2'>
                            {hero.buttonText}
                          </button>
                        </Link>
                        {/* <Link to='/contact-us'>
                  <button className='bton bton--ghost bton--md'>
                    Learn&nbsp;More
                  </button>
                </Link> */}
                      </section>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          );
        }}
      </Query>
    </Fragment>
  );
}

export default Index;
